import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from '../../../../app/providers/StoreProvider';
import { ApiError } from '../../../../shared/types';
import { handleError } from '../../../../shared/lib/helpers/utils';
import { User } from '../../../User';

export const fetchProfile = createAsyncThunk<
  User,
  void,
  ThunkConfig<ApiError>
>(
  'auth/fetchProfile',
  async (_, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
      const response = await extra.api.get<User>('/user/getUser');

      return response.data;
    } catch (err) {
      return rejectWithValue(handleError(err));
    }
  },
);
