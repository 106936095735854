import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ManageUsersSchema } from '../types/manageUsersSchema';
import { fetchList } from '../services/fetchList';

const initialState: ManageUsersSchema = {
  listFilters: {
    searchText: '',
    currentPage: 1,
  },
};

export const manageUsersSlice = createSlice({
  name: 'manageUsers',
  initialState,
  reducers: {
    setFiltersSearchText: (state, action: PayloadAction<string>) => {
      state.listFilters.searchText = action.payload;
    },
    setFiltersCurrentPage: (state, action: PayloadAction<number>) => {
      state.listFilters.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchList.pending, (state) => {
        state.listIsLoading = true;
        state.listError = undefined;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.listIsLoading = false;
        state.listData = action.payload;
      })
      .addCase(fetchList.rejected, (state, action) => {
        state.listIsLoading = false;
        state.listError = action.payload;
      });
  },
});

export const { actions: manageUsersActions, reducer: manageUsersReducer } = manageUsersSlice;
