import { lazy } from 'react';

import { SignUpAsync } from '../../../../pages/auth/SignUp';
import { AddPharmacyAsync } from '../../../../pages/user/AddPharmacy';
import { AppRouteProps } from '../model/types';
import {
  getRouteAddPharmacy,
  getRouteAdminBuyerWishlist,
  getRouteAdminChangePassword,
  getRouteAdminDashboard,
  getRouteAdminManageNdc,
  getRouteAdminPotentialOffers,
  getRouteAdminApplicationSettings,
  getRouteAdminManageOrders,
  getRouteAdminManageUserDetails,
  getRouteAdminManageUsers,
  getRouteAdminProfile,
  getRouteAdminSellerWishlist,
  getRouteBuyerOrders,
  getRouteBuyerWishlist,
  getRouteCommission,
  getRouteCommissionRecord,
  getRouteIncomingSales,
  getRouteLogin,
  getRouteNoAccess,
  getRouteSellAddNew,
  getRouteSellerOrders,
  getRouteSellWishlist,
  getRouteSignUp,
  getRouteUserChangePassword,
  getRouteUserProfile,
} from '../../../../shared/const/router';
import { LoginAsync } from '../../../../pages/auth/Login';
import { NoAccessAsync } from '../../../../pages/user/NoAccess';
import { UserRole } from '../../../../entities/User';
import { ManageNdcAsync } from '../../../../pages/admin/ManageNdc';
import { SellWishlistAsync } from '../../../../pages/user/SellWishlist';
import { SellAddNewAsync } from '../../../../pages/user/SellAddNew';
import { DashboardAsync } from '../../../../pages/admin/Dashboard';
import { ManageUsersAsync } from '../../../../pages/admin/ManageUsers';
import { PotentialOffersAsync } from '../../../../pages/admin/PotentialOffers';
import { ApplicationSettingsAsync } from '../../../../pages/admin/ApplicationSettings';

export const routes: AppRouteProps[] = [
  {
    path: '/',
    component: lazy(() => import('../../../../pages/auth/LandingPage')),
    isPublic: true,
    role: [UserRole.USER, UserRole.ADMIN],
  },
  {
    path: getRouteLogin(),
    component: LoginAsync,
    isPublic: true,
    role: [UserRole.USER, UserRole.ADMIN],
  },
  {
    path: getRouteSignUp(),
    component: SignUpAsync,
    isPublic: true,
    role: [UserRole.USER, UserRole.ADMIN],
  },
  {
    path: '/otpVerification/:type',
    component: lazy(() => import('../../../../pages/auth/OtpVerification')),
    isPublic: true,
    role: [UserRole.USER, UserRole.ADMIN],
  },
  {
    path: getRouteAddPharmacy(),
    component: AddPharmacyAsync,
    isPublic: false,
    role: [UserRole.USER, UserRole.ADMIN],
    withoutLayout: true,
  },
  {
    path: '/forgotPassword',
    component: lazy(() => import('../../../../pages/auth/ForgotPassword')),
    isPublic: true,
    role: [UserRole.USER, UserRole.ADMIN],
  },
  {
    path: '/resetPassword',
    component: lazy(() => import('../../../../pages/auth/ResetPassword')),
    isPublic: true,
    role: [UserRole.USER, UserRole.ADMIN],
  },
  {
    path: getRouteNoAccess(),
    component: NoAccessAsync,
    isPublic: false,
    role: [UserRole.USER, UserRole.ADMIN],
  },
  {
    path: getRouteUserProfile(),
    component: lazy(() => import('../../../../pages/user/Profile')),
    isPublic: false,
    role: [UserRole.USER],
  },
  {
    path: getRouteUserChangePassword(),
    component: lazy(() => import('../../../../pages/user/ChangePassword')),
    isPublic: false,
    role: [UserRole.USER],
  },
  {
    path: getRouteBuyerWishlist(),
    component: lazy(() => import('../../../../pages/user/Buyer/Wishlist')),
    isPublic: false,
    role: [UserRole.USER],
  },
  {
    path: getRouteSellAddNew(),
    component: SellAddNewAsync,
    isPublic: false,
    role: [UserRole.USER],
  },
  {
    path: getRouteSellWishlist(),
    component: SellWishlistAsync,
    isPublic: false,
    role: [UserRole.USER],
  },
  {
    path: '/user/buyer/potentialBuys/:buyerWishlistId',
    component: lazy(() => import('../../../../pages/user/Buyer/PotentialBuy')),
    isPublic: false,
    role: [UserRole.USER],
  },
  {
    path: getRouteIncomingSales(),
    component: lazy(() => import('../../../../pages/user/Seller/IncomingSale')),
    isPublic: false,
    role: [UserRole.USER],
  },
  {
    path: getRouteSellerOrders(),
    component: lazy(() => import('../../../../pages/user/Seller/Order')),
    isPublic: false,
    role: [UserRole.USER],
  },
  {
    path: getRouteBuyerOrders(),
    component: lazy(() => import('../../../../pages/user/Buyer/Order')),
    isPublic: false,
    role: [UserRole.USER],
  },
  {
    path: getRouteAdminProfile(),
    component: lazy(() => import('../../../../pages/admin/Profile')),
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: getRouteAdminChangePassword(),
    component: lazy(() => import('../../../../pages/admin/ChangePassword')),
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: getRouteAdminManageUserDetails(':userId'),
    component: lazy(() => import('../../../../pages/admin/User/UserProfile')),
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: getRouteCommission(),
    component: lazy(() => import('../../../../pages/admin/Commission')),
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: getRouteCommissionRecord(),
    component: lazy(() => import('../../../../pages/admin/CommissionRecord')),
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: getRouteAdminBuyerWishlist(),
    component: lazy(() => import('../../../../pages/admin/Buyer/Wishlist')),
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: getRouteAdminSellerWishlist(),
    component: lazy(() => import('../../../../pages/admin/Seller/Wishlist')),
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: getRouteAdminManageUsers(),
    component: ManageUsersAsync,
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: '/admin/matchingWishlist/:wishlistId',
    component: lazy(() => import('../../../../pages/admin/MatchingWishlist')),
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: '/adminView/userProfile/:userId',
    component: lazy(() => import('../../../../pages/admin/User/UserProfile')),
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: getRouteAdminManageOrders(),
    component: lazy(() => import('../../../../pages/admin/Order')),
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: getRouteAdminManageNdc(),
    component: ManageNdcAsync,
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: getRouteAdminPotentialOffers(),
    component: PotentialOffersAsync,
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: getRouteAdminApplicationSettings(),
    component: ApplicationSettingsAsync,
    isPublic: false,
    role: [UserRole.ADMIN],
  },
  {
    path: getRouteAdminDashboard(),
    component: DashboardAsync,
    isPublic: false,
    role: [UserRole.ADMIN],
  },
];
