export const getRouteHome = () => '/';
export const getRouteAddPharmacy = () => '/add-pharmacy';
export const getRouteBuyerWishlist = () => '/user/buyer/wishlist';
export const getRouteBuyerOrders = () => '/user/buyer/order';
export const getRouteSellerOrders = () => '/user/seller/order';
export const getRouteUserProfile = () => '/user/profile';
export const getRouteUserChangePassword = () => '/user/change-password';
export const getRouteNoAccess = () => '/no-access';
export const getRouteLogin = () => '/login';
export const getRouteSignUp = () => '/signup';
export const getRouteIncomingSales = () => '/user/seller/incomingSale';
export const getRouteAdminProfile = () => '/admin/profile';
export const getRouteAdminChangePassword = () => '/admin/changePassword';
export const getRouteCommission = () => '/admin/commission';
export const getRouteCommissionRecord = () => '/admin/commissionRecord';
export const getRouteAdminBuyerWishlist = () => '/admin/buyer/wishlist';
export const getRouteAdminSellerWishlist = () => '/admin/seller/wishlist';
export const getRouteAdminManageUsers = () => '/admin/manage/users';
export const getRouteAdminManageUserDetails = (id) => `/admin/manage/users/${id}`;
export const getRouteAdminManageOrders = () => '/admin/manage/orders';
export const getRouteAdminManageNdc = () => '/admin/manage/ndc';
export const getRouteAdminPotentialOffers = () => '/admin/potential-offers';
export const getRouteAdminApplicationSettings = () => '/admin/application-settings';
export const getRouteSellAddNew = () => '/sell/add';
export const getRouteSellWishlist = () => '/sell/wishlist';
export const getRouteAdminDashboard = () => '/admin';
