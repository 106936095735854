import { createSlice } from '@reduxjs/toolkit';

import { ApplicationSettingsSchema } from '../types/applicationSettingsSchema';
import { fetchSettings } from '../services/fetchSettings';

const initialState: ApplicationSettingsSchema = {};

export const applicationSettingsSlice = createSlice({
  name: 'applicationSettings',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { actions: applicationSettingsActions, reducer: applicationSettingsReducer } = applicationSettingsSlice;
