// @ts-nocheck
import React, { Suspense, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../shared/lib/hooks/useAppDispatch';
import { useUser } from '../shared/lib/hooks/useUser';
import { authActions, fetchProfile, getAuthIsReady } from '../entities/Auth';
import { AppRouter } from './providers/Router';
import { getNotificationToken, onMessageListener } from './firebase';
import { getUserNotifications } from '../shared/redux/features/User/userApi';
import NotificationToaster from '../shared/NotificationToaster';
import LoadingScreen from '../shared/LoadingScreen';

import './app.scss';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-date-range/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/theme/default.css';

export const App = () => {
  const dispatch = useAppDispatch();
  const user = useUser();

  const isAuthReady = useSelector(getAuthIsReady);

  const [notification, setNotification] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  const fetchUserNotifications = () => {
    setTimeout(() => {
      if (user) {
        dispatch(getUserNotifications());
      }
    }, 500);
  };

  const setToken = async () => {
    const firebaseToken = await getNotificationToken();
    if (firebaseToken) {
      dispatch(authActions.setFirebaseToken(firebaseToken));
    }
  };

  useEffect(() => {
    setToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  onMessageListener()
    .then((payload) => {
      const { body } = payload.notification;
      const { title } = payload.notification;
      setNotification({
        title,
        body,
      });
      fetchUserNotifications();
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <Suspense fallback={<LoadingScreen />}>
      { isAuthReady ? (
        <>
          <ToastContainer />
          <NotificationToaster
            showNotification={showNotification}
            setShowNotification={setShowNotification}
            notificationData={notification}
          />
          <AppRouter />
        </>
      ) : <LoadingScreen />}
    </Suspense>
  );
};
