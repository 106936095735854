import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from '../../../../../app/providers/StoreProvider';
import { ApiError } from '../../../../../shared/types';
import { handleError } from '../../../../../shared/lib/helpers/utils';

export const skipOffer = createAsyncThunk<
  void,
  {
    sellerWishlistId: number,
    buyerWishlistId: number,
  },
  ThunkConfig<ApiError>
>(
  'potentialOffers/skipOffer',
  async (params, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
      await extra.api.post('/admin/potential-offers/skip', params);
    } catch (err) {
      return rejectWithValue(handleError(err));
    }
  },
);
