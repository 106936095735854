import React, { memo } from "react";
import { Modal as ReactstrapModal, ModalBody, ModalFooter } from "reactstrap";

const InformationModal = ({ isOpen, content, footer, size = "md" }) => {
  return (
    <ReactstrapModal
      size={size}
      centered={true}
      backdrop={"static"}
      isOpen={isOpen}
    >
      <div className="pt-3 pb-1">
        <ModalBody className="text-center">
          <div className="text-custom-dark fs-5">{content}</div>
        </ModalBody>
        {footer && (
          <ModalFooter className="outline-0 outline border-0">
            {footer}
          </ModalFooter>
        )}
      </div>
    </ReactstrapModal>
  );
};

export default memo(InformationModal);
