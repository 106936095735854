import { isAxiosError } from 'axios';
import { ObjectSchema } from 'yup';

import { ApiError } from '../../types';

export const handleError = (error: Record<string, any>): ApiError => {
  const result: ApiError = {};

  if (isAxiosError(error)) {
    result.details = error.response?.data?.message || error.message;
  } else {
    result.details = (error as Error).message;
  }

  return result;
};

export const handleValidation = (schema: ObjectSchema<any>, data: Record<string, any>): ApiError => {
  const errors: ApiError = {};

  try {
    schema.validateSync(data, { abortEarly: false });
  } catch (err) {
    err.inner.forEach((item) => {
      if (item.path) {
        const [text] = item.errors;
        errors[item.path] = text;
      }
    });
  }

  return errors;
};

export const getRange = (start: number, end: number) => {
  return Array.from({ length: end - start + 1 }, (_, idx) => idx + start);
};
