import { Link } from 'react-router-dom';

import { getRouteHome } from '../../../shared/const/router';

import cls from './NotFound.module.scss';

const NotFound = () => {
  return (
    <div className={cls.NotFound}>
      <p>
        Page not found
      </p>
      <p>
        Sorry we couldn't find that page, try next actions below
      </p>
      <Link
        to={getRouteHome()}
      >
        Home Page
      </Link>
    </div>
  );
};

export default NotFound;
