// otp flow types
export const OTP_SIGNUP_TYPE = 'signUp';
export const OTP_FORGOT_PASSWORD_TYPE = 'forgotPassword';

// potential buy status
export const NEW_POTENTIAL_BUY_STATUS = 'new';
export const PENDING_POTENTIAL_BUY_STATUS = 'pending';
export const REJECTED_POTENTIAL_BUY_STATUS = 'rejected';
export const ACCEPTED_POTENTIAL_BUY_STATUS = 'accepted';
export const CANCELLED_POTENTIAL_BUY_STATUS = 'cancelled';

// user statuses
export const ACTIVE_USER_STATUS = 'active';
export const INACTIVE_USER_STATUS = 'inactive';
export const BLOCKED_USER_STATUS = 'blocked';
export const PENDING_USER_STATUS = 'pending';

// wishlist statuses
export const PENDING_WISHLIST_STATUS = 'pending';
export const COMPLETED_WISHLIST_STATUS = 'completed';
export const CLOSED_WISHLIST_STATUS = 'closed';

// static routes
export const PUBLIC_INITIAL_ROUTE = '/';
export const USER_DASHBOARD_ROUTE = '/user/buyer/wishlist';
export const ADMIN_DASHBOARD_ROUTE = '/admin/buyer/wishlist';

// pagination prams
export const PAGE_RANGE = 2;
export const PER_PAGE_COUNT = 8;

// user types
export const BUYER_TYPE = 'buyer';
export const SELLER_TYPE = 'seller';

// order status
export const PENDING_ORDER_STATUS = 'pending';
export const ACCEPTED_ORDER_STATUS = 'accepted';
export const CANCEL_ORDER_STATUS = 'cancel';
export const LOCKED_ORDER_STATUS = 'locked';
export const REJECTED_ORDER_STATUS = 'rejected';

// user roles
export const USER_ROLE = 'user';
export const ADMIN_ROLE = 'admin';

// api endpoints
export const SIGNUP_URL = '/auth/signup';
export const VERIFY_OTP_URL = '/auth/verifyOtp';
export const RESEND_OTP_URL = '/auth/resendOtp';
export const EDIT_PROFILE_URL = '/user/updateUser';
export const CANCEL_ORDER_URL = '/order/cancelOrder';
export const GET_NDC_DETAIL_URL = '/ndc';
export const DELETE_ACCOUNT_URL = '/user/deleteAccount';
export const RESET_PASSWORD_URL = '/auth/resetPassword';
export const ADMIN_GET_USER_PROFILE_URL = '/user/:userId';
export const CHANGE_PASSWORD_URL = '/user/changePassword';
export const FORGOT_PASSWORD_URL = '/auth/forgotPassword';
export const GET_BUYER_ORDERS_URL = '/order/getBuyerOrders';
export const BUYER_GET_WISHLIST_URL = '/wishlist/getWishlist';
export const GET_SELLER_ORDERS_URL = '/order/getSellerOrders';
export const ADMIN_GET_ALL_ORDERS_URL = '/order/getallOrders';
export const SELLER_GET_WISHLIST_URL = '/wishlist/getWishlist';
export const ADD_SHIPMNET_DETAIL_URL = '/fedex/createShipment';
export const GET_PAYMENT_INTENT_URL = '/payment/paymentIntents';
export const SELLER_CHANGE_ORDER_STATUS = '/order/changeOrderStatus';
export const ADD_PHARMACY_DETAIL_URL = '/pharmacy/addPharmacyDetails';
export const WISHLIST_MARK_AS_COMPLETE_URL = '/wishlist/markAsComplete';
export const ADMIN_GET_WISHLIST_MATCHING_URL = '/wishlist/matchWishlist';
export const BUYER_GET_POTENTIAL_BUYS_URL = '/wishlist/showPotentialBuys';
export const GET_USER_NOTIFICATIONS_URL = '/notifications';
export const ADMIN_SEND_MATCHED_TO_BUYER_URL = '/admin/potential-offers/send-to-buyer';
export const ADMIN_GET_COMMISSION_VALUE_URL = '/commission/getcommissionvalue';
export const ADMIN_UPDATE_COMMISSION_VALUE_URL = '/commission/updatecommissionvalue';
export const ADMIN_GET_COMMISSION_RECORDS_URL = 'commission/getFilteredCommissionRecords';
export const USER_NOTIFICATIONS_MARK_ASREAD_URL = '/notifications/updateNotifications';

export const fedexServiceTypeOptions = [
  {
    label: 'Fedex Ground',
    value: 'FEDEX_GROUND',
  },
  {
    label: 'Fedex Standard Overnight',
    value: 'STANDARD_OVERNIGHT',
  },
];

export const fedexServiceTypes = {
  GROUND: 'FEDEX_GROUND',
  PRIORITY: 'STANDARD_OVERNIGHT',
};

export const fedexRateServiceTypes = {
  GROUND: 'FEDEX_GROUND',
  PRIORITY: 'STANDARD_OVERNIGHT',
};

export const notificationTabLabels = [
  { key: 'buyer', label: 'Buyer' },
  { key: 'seller', label: 'Seller' },
];

export const packageSizeOptions = [
  {
    label: 'Full Container',
    value: 'full',
  },
  {
    label: 'Partial Container',
    value: 'partial',
  },
];

export const packageConditionOptions = [
  {
    label: 'Sealed',
    value: 'sealed',
  },
  {
    label: 'Unsealed',
    value: 'unsealed',
  },
  {
    label: 'Torn Label',
    value: 'tornLabel',
  },
  {
    label: 'Writing on bottle',
    value: 'writingOnBottle',
  },
  {
    label: 'Sticker Residue',
    value: 'stickerResedue',
  },
  {
    label: 'Vial',
    value: 'vial',
  },
];
