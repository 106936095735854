// @ts-nocheck
import { configureStore, ReducersMapObject } from '@reduxjs/toolkit';
import { CombinedState, Reducer } from 'redux';

import { createReducerManager } from '../lib/createReducerManager';
import { StateSchema, ThunkExtraArg } from './StateSchema';
import { api } from '../../../../shared/api/api';
import { rtkApi } from '../../../../shared/api/rtkApi';
import { authReducer } from '../../../../entities/Auth';
// deprecated
import { userReducer } from '../../../../shared/redux/features/User/userSlice';
import { buyerReducer } from '../../../../shared/redux/features/Buyer/buyerSlice';
import { adminReducer } from '../../../../shared/redux/features/Admin/adminSlice';
import { sellerReducer } from '../../../../shared/redux/features/Seller/sellerSlice';

export function createReduxStore(
  initialState?: StateSchema,
  asyncReducers?: ReducersMapObject<StateSchema>,
) {
  const rootReducers: ReducersMapObject<StateSchema> = {
    ...asyncReducers,
    auth: authReducer,
    [rtkApi.reducerPath]: rtkApi.reducer,
    // deprecated
    user: userReducer,
    buyer: buyerReducer,
    seller: sellerReducer,
    admin: adminReducer,
  };

  const reducerManager = createReducerManager(rootReducers);

  const extraArg: ThunkExtraArg = {
    api,
  };

  const store = configureStore({
    reducer: reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
    devTools: true,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      thunk: {
        extraArgument: extraArg,
      },
    }).concat(rtkApi.middleware),
  });

  // @ts-expect-error
  store.reducerManager = reducerManager;

  return store;
}

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch'];
