import { createAsyncThunk } from '@reduxjs/toolkit';

import { User, UserStatus } from '../../../../../entities/User';
import { ThunkConfig } from '../../../../../app/providers/StoreProvider';
import { ApiError } from '../../../../../shared/types';
import { handleError } from '../../../../../shared/lib/helpers/utils';
import { SortOrder } from '../../../../../shared/const/table';

export interface FetchListParams {
  status?: UserStatus;
  order?: SortOrder;
}

export const fetchList = createAsyncThunk<
  User[],
  FetchListParams,
  ThunkConfig<ApiError>
>(
  'manageUsers/fetchList',
  async (params, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    let url = '/user?';
    if (params.status) {
      url += `&status=${params.status}`;
    }
    if (params.order) {
      url += `&order=${params.order}`;
    }

    try {
      const response = await extra.api.get<{ count: number, rows: User[]}>(url);

      return response.data.rows;
    } catch (err) {
      return rejectWithValue(handleError(err));
    }
  },
);
