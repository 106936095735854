// @ts-nocheck
import { createAsyncThunk } from '@reduxjs/toolkit';

import Toaster from '../../../Toaster';

export const addNdc = createAsyncThunk(
  'addNdc',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.post(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const getWishList = createAsyncThunk(
  'getWishList',
  async ({ apiEndpoint }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.get(apiEndpoint);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const getPotentialBuys = createAsyncThunk(
  'getPotentialBuys',
  async ({ apiEndpoint }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.get(apiEndpoint);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const createOrder = createAsyncThunk(
  'createOrder',
  async ({ requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.post('/order', requestData);
      Toaster.success(response?.data?.message);
      return response;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const markWishlistAsCompleted = createAsyncThunk(
  'markWishlistAsCompleted',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.patch(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const getBuyerOrders = createAsyncThunk(
  'getBuyerOrders',
  async ({ apiEndpoint }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.get(apiEndpoint);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const cancelOrder = createAsyncThunk(
  'cancelOrder',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.post(apiEndpoint, requestData);
      Toaster.success('Order cancelled successfully');
      return response;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue({ statusCode: error.response.status });
    }
  },
);

export const getPaymentIntent = createAsyncThunk(
  'getPaymentIntent',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.post(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getShippmentRates = createAsyncThunk(
  'getShippmentRates',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.post(apiEndpoint, requestData);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);
