// @ts-nocheck
import { createAsyncThunk } from '@reduxjs/toolkit';

import Toaster from '../../../Toaster';
import { GET_USER_NOTIFICATIONS_URL } from '../../../utils/constants';
import { authActions } from '../../../../entities/Auth';
import { USER_LOCAL_STORAGE_KEY } from '../../../const/localStorage';

export const forgotPassword = createAsyncThunk(
  'forgotPassword',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.post(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response?.data?.data?.id;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const resendOtp = createAsyncThunk(
  'resendOtp',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.put(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const signUp = createAsyncThunk(
  'signUp',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.post(apiEndpoint, requestData);
      return response?.data?.user;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const verifyOtp = createAsyncThunk(
  'verifyOtp',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, dispatch, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.post(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);

      localStorage.setItem(USER_LOCAL_STORAGE_KEY, response.data.token);
      dispatch(authActions.setUserData(response.data.user));

      return response?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.patch(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const changePassword = createAsyncThunk(
  'changePassword',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.patch(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const editProfile = createAsyncThunk(
  'editProfile',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.put(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const deleteAccount = createAsyncThunk(
  'deleteAccount',
  async ({ apiEndpoint }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.delete(apiEndpoint);
      Toaster.success(response?.data?.message);
      return response.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getUserProfile = createAsyncThunk(
  'getUserProfile',
  async (_, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.get('/user/getUser');
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getUserPharmacy = createAsyncThunk(
  'getUserPharmacy',
  async (_, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.get('/pharmacy');
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getNDCDetail = createAsyncThunk(
  'getNDCDetail',
  async ({ apiEndpoint }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.get(apiEndpoint);
      return response?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const addPharmacyDetail = createAsyncThunk(
  'addPharmacyDetail',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.post(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getUserNotifications = createAsyncThunk(
  'getUserNotifications',
  async (_, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.get(GET_USER_NOTIFICATIONS_URL);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.error?.detail);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getPaymentMethodCheckoutSessionUrl = createAsyncThunk(
  'getPaymentMethodCheckoutSessionUrl',
  async ({ apiEndpoint }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.get(apiEndpoint);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.error?.detail);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const markAsReadNotification = createAsyncThunk(
  'markAsReadNotification',
  async ({ apiEndpoint, requestData }, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.put(apiEndpoint, requestData);
      Toaster.success(response?.data?.message);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.error?.detail);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const addAccountPaymentMethod = createAsyncThunk(
  'addAccountPaymentMethod',
  async (params, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.post('/account/payment-method', params);
      Toaster.success(response?.data?.message);
      return response?.data?.data;
    } catch (error) {
      Toaster.error(error?.response?.data?.message);
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getAccountPaymentMethods = createAsyncThunk(
  'getAccountPaymentMethods',
  async (_, thunkAPI) => {
    const { extra, rejectWithValue } = thunkAPI;

    try {
      const response = await extra.api.get('/account/payment-method');
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  },
);
