import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthSchema } from '../types/authSchema';
import { fetchProfile } from '../services/fetchProfile';
import { login } from '../services/login';
import { logout } from '../services/logout';
import { User } from '../../../User';

const initialState: AuthSchema = {};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsReady: (state, action: PayloadAction<boolean>) => {
      state.isReady = action.payload;
    },
    setFirebaseToken: (state, action: PayloadAction<string>) => {
      state.firebaseToken = action.payload;
    },
    setUserData: (state, action: PayloadAction<User>) => {
      state.userData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.fulfilled, (state, action: PayloadAction<User>) => {
        state.isReady = true;
        state.userData = action.payload;
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.isReady = true;
      })
      .addCase(login.pending, (state) => {
        state.userIsLoading = true;
        state.userError = undefined;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<User>) => {
        state.userIsLoading = false;
        state.userData = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.userIsLoading = false;
        state.userError = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.userData = undefined;
      })
      .addCase(logout.rejected, (state) => {
        state.userData = undefined;
      });
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;
