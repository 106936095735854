import React, { useCallback, useState } from 'react';

import Topbar from '../../Topbar';
import { UserSidebar } from './UserSidebar';
import { AdminSidebar } from './AdminSidebar';
import { useUser } from '../../lib/hooks/useUser';
import { UserRole } from '../../../entities/User';

import cls from './Layout.module.scss';

const GeneralLayout = ({ withoutLayout, children }) => {
  const user = useUser();

  const [toggleSidebar, setToggleSidebar] = useState(true);

  const setToogle = useCallback((value) => {
    setToggleSidebar(value);
  }, []);

  if (withoutLayout || !user) {
    return (
      <div className={cls.Content}>{children}</div>
    );
  }

  return (
    <>
      {user.role === UserRole.ADMIN ? (
        <AdminSidebar
          toggleSidebar={toggleSidebar}
          setToggleSidebar={setToogle}
        />
      ) : (
        <UserSidebar
          toggleSidebar={toggleSidebar}
          setToggleSidebar={setToogle}
        />
      )}
      <div
        className={`transition vh-100 overflow-y-scroll ${
          toggleSidebar && window.innerWidth > 576 ? 'openSidebar' : ''
        }`}
      >
        <Topbar
          toggleSidebar={toggleSidebar}
          setToggleSidebar={setToogle}
        />
        <div className={cls.Content}>{children}</div>
      </div>
    </>
  );
};

export default GeneralLayout;
