// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';

import {
  signUp,
  resendOtp,
  verifyOtp,
  editProfile,
  getNDCDetail,
  deleteAccount,
  resetPassword,
  changePassword,
  getUserProfile,
  getUserPharmacy,
  forgotPassword,
  addPharmacyDetail,
  getUserNotifications,
  markAsReadNotification,
  getPaymentMethodCheckoutSessionUrl,
} from './userApi';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    userPharmacy: null,
    error: null,
    token: null,
    userId: null,
    session: null,
    unreadCount: 0,
    loading: 'idle',
    verifyToken: null,
    userNotifications: [],
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(signUp.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.userId = action?.payload?.id;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload?.error;
      })
      .addCase(verifyOtp.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.user = action.payload?.user;
        state.token = action.payload?.token;
        state.userId = null;
        state.verifyToken = action.payload;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload?.error;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.userId = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload?.error;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = 'succeeded';
        state.verifyToken = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload?.error;
      })
      .addCase(editProfile.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(editProfile.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.user = action.payload;
      })
      .addCase(editProfile.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload?.error;
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(changePassword.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(resendOtp.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(resendOtp.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(resendOtp.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(addPharmacyDetail.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(addPharmacyDetail.fulfilled, (state) => {
        state.loading = 'succeeded';
        state.verifyToken = null;
      })
      .addCase(addPharmacyDetail.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(getUserNotifications.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getUserNotifications.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.userNotifications = action?.payload?.notifications;
        state.unreadCount = action?.payload?.unreadCount;
      })
      .addCase(getUserNotifications.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(deleteAccount.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteAccount.fulfilled, (state) => {
        state.user = null;
        state.token = null;
        state.loading = 'succeeded';
      })
      .addCase(deleteAccount.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(getNDCDetail.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getNDCDetail.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(getNDCDetail.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(getUserProfile.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = 'succeeded';
      })
      .addCase(getUserProfile.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(getUserPharmacy.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getUserPharmacy.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.userPharmacy = action.payload;
      })
      .addCase(getUserPharmacy.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(markAsReadNotification.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(markAsReadNotification.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(markAsReadNotification.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(getPaymentMethodCheckoutSessionUrl.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(
        getPaymentMethodCheckoutSessionUrl.fulfilled,
        (state, action) => {
          state.loading = 'succeeded';
        },
      )
      .addCase(getPaymentMethodCheckoutSessionUrl.rejected, (state) => {
        state.loading = 'failed';
      });
  },
});

export const { actions: userActions, reducer: userReducer } = userSlice;
