import { createAsyncThunk } from '@reduxjs/toolkit';

import { USER_LOCAL_STORAGE_KEY } from '../../../../shared/const/localStorage';
import { ThunkConfig } from '../../../../app/providers/StoreProvider';
import { ApiError } from '../../../../shared/types';
import { handleError } from '../../../../shared/lib/helpers/utils';
import { getAuthFirebaseToken } from '../selectors/getAuthSelectors';

export const logout = createAsyncThunk<
  void,
  void,
  ThunkConfig<ApiError>
>(
  'auth/logout',
  async (_, thunkApi) => {
    const { extra, getState, rejectWithValue } = thunkApi;

    const firebaseToken = getAuthFirebaseToken(getState());

    try {
      await extra.api.post('/auth/logout', {
        deviceToken: firebaseToken,
      });

      localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
    } catch (err) {
      return rejectWithValue(handleError(err));
    }
  },
);
