import { Button } from 'reactstrap';
import { SlLogout } from 'react-icons/sl';
import { RxCross2 } from 'react-icons/rx';
import { PiGearFineBold } from 'react-icons/pi';
import { TbTargetArrow } from 'react-icons/tb';
import { FaNotesMedical } from 'react-icons/fa';
import { IoPerson } from 'react-icons/io5';
import { MdOutlineDashboard } from 'react-icons/md';
import { FaCartPlus } from 'react-icons/fa6';
import { HiUsers } from 'react-icons/hi2';
import React, { memo, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { IMAGES } from '../../const/images';
import Toaster from '../../Toaster';
import {
  getRouteAdminDashboard,
  getRouteAdminManageNdc, getRouteAdminPotentialOffers,
  getRouteAdminManageOrders,
  getRouteAdminManageUsers,
  getRouteCommission,
  getRouteCommissionRecord, getRouteAdminApplicationSettings,
} from '../../const/router';
import { useAppDispatch } from '../../lib/hooks/useAppDispatch';
import { logout } from '../../../entities/Auth';

export const AdminSidebar = memo(({ toggleSidebar, setToggleSidebar }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const handleLogoutClick = useCallback(() => {
    dispatch(logout());
    Toaster.success('You have successfully logged out');
  }, [dispatch]);

  return (
    <div
      className="sidebar-content shadow-lg transition position-absolute"
      style={{
        width: toggleSidebar ? '260px' : '0px',
        overflow: 'hidden',
      }}
    >
      <div className="d-flex py-3 justify-content-center ">
        <div className="d-flex align-items-center justify-content-between gap-2 mb-4">
          <img src={IMAGES.LOGO_IMG} alt="Parry Script" width="30px" />
          <h5>PARRY SCRIPT</h5>
        </div>
        <div>
          {window.innerWidth <= 576 && (
            <Button
              onClick={() => setToggleSidebar(!toggleSidebar)}
              className="border-0 cursor-pionter text-align-center bg-transparent"
            >
              <RxCross2 className="text-danger" />
            </Button>
          )}
        </div>
      </div>

      <Menu className="Menu fw-bolder overflow-hidden">
        <Link
          className={`${
            pathname === getRouteAdminDashboard()
              ? 'activeMenuItem'
              : 'MenuItem'
          }  text-light`}
          to={getRouteAdminDashboard()}
          style={{ textDecoration: 'none' }}
        >
          <MenuItem icon={<MdOutlineDashboard size={18} />}>Dashboard</MenuItem>
        </Link>
        <Link
          className={`${
            pathname === getRouteAdminPotentialOffers()
              ? 'activeMenuItem'
              : 'MenuItem'
          }  text-light`}
          to={getRouteAdminPotentialOffers()}
          style={{ textDecoration: 'none' }}
        >
          <MenuItem icon={<TbTargetArrow size={18} />}>Potential Offers</MenuItem>
        </Link>
        <SubMenu
          icon={<FaCartPlus size={18} />}
          label="Wishlist"
          className="subMenu text-white"
        >
          <Link
            to="/admin/buyer/wishlist"
            className="decorationNone text-white"
          >
            <MenuItem
              className={`${
                pathname.includes('/buyer/wishlist')
                  ? 'activeSubMenuItem'
                  : 'subMenuItem'
              }`}
            >
              Buyer Wishlist
            </MenuItem>
          </Link>

          <Link
            to="/admin/seller/wishlist"
            className="decorationNone text-white"
          >
            <MenuItem
              className={`${
                pathname.includes('/seller/wishlist')
                  ? 'activeSubMenuItem'
                  : 'subMenuItem'
              }`}
            >
              Seller Wishlist
            </MenuItem>
          </Link>
        </SubMenu>
        <SubMenu
          icon={<FaCartPlus size={18} />}
          label="Commission"
          className="subMenu text-white"
        >
          <Link
            className={`${
              /^\/admin\/commission\/?$/.test(pathname)
                ? 'activeMenuItem'
                : 'MenuItem'
            }  text-light`}
            to={getRouteCommission()}
            style={{ textDecoration: 'none' }}
          >
            <MenuItem icon={<IoPerson size={18} />}>
              Commission Value
            </MenuItem>
          </Link>
          <Link
            className={`${
              pathname.includes(getRouteCommissionRecord())
                ? 'activeMenuItem'
                : 'MenuItem'
            }  text-light`}
            to={getRouteCommissionRecord()}
            style={{ textDecoration: 'none' }}
          >
            <MenuItem icon={<IoPerson size={18} />}>
              Commission Record
            </MenuItem>
          </Link>
        </SubMenu>
        <Link
          className={`${
            pathname.includes(getRouteAdminManageUsers())
              ? 'activeMenuItem'
              : 'MenuItem'
          }  text-light`}
          to={getRouteAdminManageUsers()}
          style={{ textDecoration: 'none' }}
        >
          <MenuItem icon={<HiUsers size={18} />}>Manage Users</MenuItem>
        </Link>
        <Link
          className={`${
            pathname.includes(getRouteAdminManageNdc())
              ? 'activeMenuItem'
              : 'MenuItem'
          }  text-light`}
          to={getRouteAdminManageNdc()}
          style={{ textDecoration: 'none' }}
        >
          <MenuItem icon={<FaNotesMedical size={18} />}>Manage NDC</MenuItem>
        </Link>
        <Link
          className={`${
            pathname.includes(getRouteAdminManageOrders()) ? 'activeMenuItem' : 'MenuItem'
          }  text-light`}
          to={getRouteAdminManageOrders()}
          style={{ textDecoration: 'none' }}
        >
          <MenuItem icon={<IoPerson size={18} />}>Orders</MenuItem>
        </Link>
        <Link
          className={`${
            pathname.includes(getRouteAdminApplicationSettings()) ? 'activeMenuItem' : 'MenuItem'
          }  text-light`}
          to={getRouteAdminApplicationSettings()}
          style={{ textDecoration: 'none' }}
        >
          <MenuItem icon={<PiGearFineBold size={20} />}>Settings</MenuItem>
        </Link>
        <MenuItem
          className="MenuItem position-absolute bottom-0 w-100 text-light"
          icon={<SlLogout size={18} />}
          onClick={handleLogoutClick}
        >
          Log Out
        </MenuItem>
      </Menu>
    </div>
  );
});
