import { FaBell } from 'react-icons/fa';
import { FaTrashCan } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { IoIosLock, IoMdPerson } from 'react-icons/io';
import { useSelector } from 'react-redux';
import React, {
  memo, useCallback, useMemo, useState,
} from 'react';
import { RiLogoutCircleLine, RiMenu2Line } from 'react-icons/ri';
import {
  Nav,
  Navbar,
  Button,
  NavbarBrand,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import FillBtn from '../Buttons/FillBtn';
import functions from '../utils/functions';
import OutlineBtn from '../Buttons/OutlineBtn';
import NotificationTabs from '../Notifications';
import InformationModal from '../Modals/InformationModal';
import { notificationTabLabels, DELETE_ACCOUNT_URL } from '../utils/constants';
import { deleteAccount } from '../redux/features/User/userApi';
import { getRouteLogin, getRouteUserChangePassword, getRouteUserProfile } from '../const/router';
import { IMAGES } from '../const/images';
import { useAppDispatch } from '../lib/hooks/useAppDispatch';
import { logout } from '../../entities/Auth';
import { useUser } from '../lib/hooks/useUser';

import cls from './TopBar.module.scss';

const Topbar = ({ toggleSidebar, setToggleSidebar }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useUser();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const { userNotifications } = useSelector(
    (state) => state.user,
  );

  const badgeCount = useMemo(() => {
    return (userNotifications || []).filter((item) => item.data?.status === 'new').length;
  }, [userNotifications]);

  const handleChangePasswordClick = useCallback(() => {
    functions.isUser(user?.role)
      ? navigate(getRouteUserChangePassword())
      : navigate('/admin/changePassword');
  }, [navigate, user]);

  const handleProfileClick = useCallback(() => {
    functions.isUser(user?.role)
      ? navigate(getRouteUserProfile())
      : navigate('/admin/profile');
  }, [navigate, user]);

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteConfirmClick = useCallback(() => {
    const data = {
      apiEndpoint: DELETE_ACCOUNT_URL,
    };
    dispatch(deleteAccount(data)).then((res) => {
      if (res.type === 'deleteAccount/fulfilled') {
        navigate(getRouteLogin());
      }
    });
  }, [dispatch, navigate]);

  const handleToggleDeleteModal = useCallback(() => {
    setShowDeleteModal(!showDeleteModal);
  }, [showDeleteModal]);

  const handleLogoutClick = useCallback(async () => {
    const result = await dispatch(logout());

    if (result.meta.requestStatus === 'fulfilled') {
      navigate(getRouteLogin());
    }
  }, [dispatch, navigate]);

  const toggleNotifications = useCallback(() => {
    setShowNotifications(!showNotifications);
  }, [showNotifications]);

  return (
    <>
      <Navbar className={`${cls.TopBar} navbar shadow-sm transition px-3`}>
        <NavbarBrand>
          <Button
            className="btn cursor-pointer bg-transparent border-0 text-dark"
            onClick={() => setToggleSidebar(!toggleSidebar)}
          >
            <RiMenu2Line size={20} />
          </Button>
        </NavbarBrand>
        <Nav className="ml-auto d-flex align-items-center gap-4">
          <div className={cls.Bell} onClick={toggleNotifications}>
            <FaBell
              className="text-primary"
              size={20}
            />
            {badgeCount > 0 && (
              <div className={cls.Badge}>
                {badgeCount}
              </div>
            )}
          </div>
          <UncontrolledDropdown>
            <DropdownToggle className="p-0" nav>
              <div
                className="bgProperties rounded-circle shadow"
                style={{
                  backgroundImage:
                    user?.profileImage === null
                      ? `url(${IMAGES.USER_DUMMY_IMG})`
                      : `url(${user?.profileImage})`,
                  width: '40px',
                  height: '40px',
                }}
              />
            </DropdownToggle>
            <DropdownMenu className="px-2">
              <DropdownItem className="p-0">
                <div
                  className="d-flex gap-1 align-items-center w-100 p-1 cursorPointer text-custom-dark"
                  onClick={handleProfileClick}
                >
                  <span className="me-2 d-flex">
                    <IoMdPerson size={20} />
                  </span>
                  <p className="mb-0">Profile</p>
                </div>
              </DropdownItem>
              <DropdownItem className="p-0">
                <div
                  className="d-flex gap-1 align-items-center w-100 p-1 cursorPointer text-custom-dark"
                  onClick={handleChangePasswordClick}
                >
                  <span className="me-2 d-flex">
                    <IoIosLock size={20} />
                  </span>
                  <p className="mb-0">Change Password</p>
                </div>
              </DropdownItem>
              {functions.isUser(user?.role) && (
                <DropdownItem className="p-0">
                  <div
                    className="d-flex gap-1 align-items-center w-100 p-1 text-custom-dark"
                    onClick={handleDeleteClick}
                  >
                    <span className="me-2 d-flex">
                      <FaTrashCan size={16} className="mb-1" />
                    </span>
                    <p className="mb-0">Delete Account</p>
                  </div>
                </DropdownItem>
              )}

              <DropdownItem className="p-0">
                <div
                  className="d-flex gap-1 align-items-center w-100 p-1 cursorPointer text-custom-dark"
                  onClick={handleLogoutClick}
                >
                  <span className="me-2 d-flex">
                    <RiLogoutCircleLine size={16} />
                  </span>
                  <p className="mb-0">Logout</p>
                </div>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        <InformationModal
          isOpen={showDeleteModal}
          content={(
            <p className="mb-0">
              Are you certain you want to delete your account?
            </p>
          )}
          footer={(
            <div className="d-flex justify-content-between w-100 gap-2">
              <FillBtn
                className="w-100 py-2"
                text="Confirm"
                handleOnClick={handleDeleteConfirmClick}
              />
              <OutlineBtn
                className="w-100 py-2"
                text="Cancel"
                handleOnClick={handleToggleDeleteModal}
              />
            </div>
          )}
        />
      </Navbar>
      {showNotifications && (
        <NotificationTabs
          notifications={userNotifications}
          tabLabels={notificationTabLabels}
          toggleNotifications={toggleNotifications}
        />
      )}
    </>
  );
};

export default memo(Topbar);
