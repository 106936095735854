import Landing from '../assets/Images/Landing/LandingPageImg.png';
import SignIn from '../assets/Images/SignIn/SidePanelSignInImg.png';
import SignUp from '../assets/Images/SignUp/SidePanelSignUpImg.png';
import OTP from '../assets/Images/Otp/SidePanelOTPImg.png';
import Password from '../assets/Images/Password/SidePanelPasswordImg.png';
import UserDummy from '../assets/Images/Profile/userDummyImg.png';
import Edit from '../assets/Images/Profile/editIcon.png';

import Logo from '../assets/Images/Common/logo.svg';

export const IMAGES = {
  LOGO_IMG: Logo,
  LANDING_IMG: Landing,
  SIDE_PANEL_SIGNIN_IMG: SignIn,
  SIDE_PANEL_SIGNUP_IMG: SignUp,
  SIDE_PANEL_OTP_IMG: OTP,
  SIDE_PANEL_PASSWORD_IMG: Password,
  USER_DUMMY_IMG: UserDummy,
  EDIT_ICON_IMG: Edit,
};
