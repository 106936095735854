import { Navigate } from 'react-router-dom';

import functions from '../../../../shared/utils/functions';
import { useUser } from '../../../../shared/lib/hooks/useUser';

export function PublicRoute({ Component, props }) {
  const user = useUser();

  if (user) {
    return (
      <Navigate
        to={functions.getInitialDashboardRoute(user?.role)}
      />
    );
  }

  return <Component {...props} />;
}
