import { RxCross2 } from 'react-icons/rx';
import React, { useState, memo } from 'react';
import {
  Nav, NavItem, TabPane, TabContent,
} from 'reactstrap';

import functions from '../utils/functions';
import {
  USER_NOTIFICATIONS_MARK_ASREAD_URL,
} from '../utils/constants';
import {
  getUserNotifications,
  markAsReadNotification,
} from '../redux/features/User/userApi';
import { useAppDispatch } from '../lib/hooks/useAppDispatch';
import { useUser } from '../lib/hooks/useUser';

import styles from './styles.module.scss';

const NotificationTabs = ({
  notifications = [],
  tabLabels = [],
  toggleNotifications,
}) => {
  const dispatch = useAppDispatch();
  const user = useUser();

  const [activeTab, setActiveTab] = useState(
    tabLabels.length > 0 ? tabLabels[0].key : null,
  );

  const toggleTab = (tabKey) => {
    if (activeTab !== tabKey) {
      setActiveTab(tabKey);
    }
  };

  const handleMarkAsReadClick = (notificationId) => {
    const data = {
      apiEndpoint: USER_NOTIFICATIONS_MARK_ASREAD_URL,
      requestData: JSON.stringify({ notificationId }),
    };

    dispatch(markAsReadNotification(data)).then((res) => {
      if (res.type === 'markAsReadNotification/fulfilled') {
        fetchUserNotifications();
      }
    });
  };

  const fetchUserNotifications = () => {
    setTimeout(() => {
      if (user) {
        dispatch(getUserNotifications());
      }
    }, 500);
  };

  const renderNotifications = (key) => {
    const groupNotifications = notifications
      ?.filter((item) => item?.userType === key)
      ?.map((notification) => (
        <div key={notification?.id}>
          <div
            className={`rounded ${
              notification?.isRead ? 'bg-light' : 'bg-highlighted'
            } shadow-sm p-2 my-2`}
          >
            <div className={`${styles.notificationUser} fw-bold p-1`}>
              {notification.title}
            </div>
            <div className={styles.notificationText}>{notification.body}</div>
            <div className={`${styles.notificationDate} fw-bold`}>
              <span>{functions.parseDateString(notification?.createdAt)}</span>
              <span
                className="cursorPointer"
                onClick={() => handleMarkAsReadClick(notification?.id)}
              >
                {!notification?.isRead && <> | Mark as read</>}
              </span>
            </div>
          </div>
        </div>
      ));

    return groupNotifications?.length > 0 ? (
      groupNotifications
    ) : (
      <div className="small py-2">No notification found</div>
    );
  };

  if (!activeTab) {
    return <div>No tabs available</div>;
  }

  return (
    <div
      className={`${styles.notificationWrapper} text-custom-dark shadow-lg rounded overflow-wrap col-xs-12 col-sm-6 col-md-5 col-lg-4`}
    >
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="fw-bold">Notifications</h6>
        <button
          className="border-0  bg-transparent"
          onClick={toggleNotifications}
        >
          <RxCross2 className="mb-2 cursor-pointer text-danger" />
        </button>
      </div>
      <Nav tabs>
        {tabLabels.map((tab) => (
          <NavItem key={tab.key} className="w-50">
            <button
              className={`${styles.navLinkButton} ${
                activeTab === tab.key ? styles.active : ''
              }`}
              onClick={() => toggleTab(tab.key)}
            >
              {tab.label}
            </button>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {tabLabels.map((tab) => (
          <TabPane tabId={tab.key} key={tab.key}>
            {renderNotifications(tab.key)}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};
export default memo(NotificationTabs);
