// @ts-nocheck
import { Suspense, memo } from 'react';
import { Routes, Route } from 'react-router-dom';

import { routes } from '../config/router';
import GeneralLayout from '../../../../shared/layouts/GeneralLayout';
import LoadingScreen from '../../../../shared/LoadingScreen';
import { PrivateRoute } from './PrivateRoutes';
import { PublicRoute } from './PublicRoutes';
import NotFound from '../../../../pages/NotFound/ui/NotFound';

export const AppRouter = memo(() => {
  return (
    <Routes>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={(
              <GeneralLayout
                isPublic={route.isPublic}
                isGuest={route.isGuest}
                isPrivate={route.isPrivate}
                isAuth={route.isAuth}
                theme={route.theme ?? 'black'}
                withoutLayout={route.withoutLayout}
              >
                <Suspense fallback={<LoadingScreen />}>
                  {!route.isPublic ? (
                    <PrivateRoute
                      props={route}
                      Component={route.component}
                    />
                  ) : (
                    <PublicRoute
                      props={route}
                      Component={route.component}
                    />
                  )}
                </Suspense>
              </GeneralLayout>
            )}
          />
        );
      })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
});
