// @ts-nocheck

import { createSlice } from '@reduxjs/toolkit';

import {
  addNdc,
  getWishList,
  createOrder,
  cancelOrder,
  getBuyerOrders,
  getPotentialBuys,
  getPaymentIntent,
  getShippmentRates,
  markWishlistAsCompleted,
} from './buyerApi';

export const buyerSlice = createSlice({
  name: 'buyer',
  initialState: {
    error: null,
    loading: 'idle',
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNdc.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(addNdc.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(addNdc.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(getWishList.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getWishList.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(getWishList.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(markWishlistAsCompleted.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(markWishlistAsCompleted.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(markWishlistAsCompleted.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(getPotentialBuys.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getPotentialBuys.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(getPotentialBuys.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(createOrder.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(createOrder.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(createOrder.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(getBuyerOrders.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getBuyerOrders.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(getBuyerOrders.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(cancelOrder.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(cancelOrder.fulfilled, (state) => {
        state.loading = 'succeeded';
      })
      .addCase(cancelOrder.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(getPaymentIntent.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getPaymentIntent.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(getPaymentIntent.rejected, (state) => {
        state.loading = 'failed';
      })
      .addCase(getShippmentRates.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(getShippmentRates.fulfilled, (state, action) => {
        state.loading = 'succeeded';
      })
      .addCase(getShippmentRates.rejected, (state) => {
        state.loading = 'failed';
      });
  },
});

export const { actions: buyerActions, reducer: buyerReducer } = buyerSlice;
