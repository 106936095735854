import { Button } from 'reactstrap';
import { SlLogout } from 'react-icons/sl';
import { RxCross2 } from 'react-icons/rx';
import { IoPerson } from 'react-icons/io5';
import React, { memo, useCallback } from 'react';
import { BiSolidCartDownload } from 'react-icons/bi';
import { Link, useLocation } from 'react-router-dom';
import { FaCartPlus } from 'react-icons/fa6';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { BsReceiptCutoff, BsGraphUpArrow } from 'react-icons/bs';

import { IMAGES } from '../../const/images';
import Toaster from '../../Toaster';
import { UserStatus } from '../../../entities/User';
import { useAppDispatch } from '../../lib/hooks/useAppDispatch';
import { useUser } from '../../lib/hooks/useUser';
import { logout } from '../../../entities/Auth';
import { getRouteSellAddNew, getRouteSellWishlist } from '../../const/router';

export const UserSidebar = memo(({ toggleSidebar, setToggleSidebar }) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const user = useUser();

  const isActiveUser = user.status === UserStatus.ACTIVE;

  const handleLogoutClick = useCallback(() => {
    dispatch(logout());
    Toaster.success('You have successfully logged out');
  }, [dispatch]);

  return (
    <div
      className="sidebar-content shadow-lg transition position-absolute"
      style={{
        width: toggleSidebar ? '260px' : '0px',
        overflow: 'hidden',
      }}
    >
      <div className="d-flex py-3 justify-content-center gap-3">
        <div>
          <div className="d-flex align-items-center justify-content-between gap-2 mb-4">
            <img src={IMAGES.LOGO_IMG} alt="Parry Script" width="30px" />
            <h5>PARRY SCRIPT</h5>
          </div>
        </div>
        <div>
          {window.innerWidth <= 576 && (
            <Button
              onClick={() => setToggleSidebar(!toggleSidebar)}
              className="border-0 cursor-pionter  bg-transparent  "
            >
              <RxCross2 size={22} className="text-danger fw-bold" />
            </Button>
          )}
        </div>
      </div>

      <Menu className="Menu fw-bolder overflow-hidden">
        {isActiveUser && (
          <>
            <SubMenu
              open
              icon={<FaCartPlus size={18} />}
              label="Sell"
              className="subMenu text-white"
            >
              <Link
                to={getRouteSellAddNew()}
                className="decorationNone text-white"
              >
                <MenuItem
                  icon={<BsReceiptCutoff size={18} />}
                  className={`${
                    pathname.includes(getRouteSellAddNew())
                      ? 'activeSubMenuItem'
                      : 'subMenuItem'
                  }`}
                >
                  Post New Item
                </MenuItem>
              </Link>
              <Link
                to={getRouteSellWishlist()}
                className="decorationNone text-white"
              >
                <MenuItem
                  icon={<BsReceiptCutoff size={18} />}
                  className={`${
                    pathname.includes(getRouteSellWishlist())
                      ? 'activeSubMenuItem'
                      : 'subMenuItem'
                  }`}
                >
                  Wishlist
                </MenuItem>
              </Link>
            </SubMenu>
            <Link
              to="/user/buyer/wishlist"
              className="decorationNone text-white"
            >
              <MenuItem
                icon={<FaCartPlus size={18} />}
                className={`${
                  pathname.includes('/buyer/wishlist')
                      || pathname.includes('/buyer/potentialBuys')
                    ? 'activeMenuItem'
                    : 'MenuItem'
                }`}
              >
                Buyer Wishlist
              </MenuItem>
            </Link>
            <Link
              className={`${
                pathname.includes('/user/seller/incomingSale')
                  ? 'activeMenuItem'
                  : 'MenuItem'
              } text-light`}
              to="/user/seller/incomingSale"
              style={{ textDecoration: 'none' }}
            >
              <MenuItem icon={<BsGraphUpArrow size={18} />}>
                Incoming Sales
              </MenuItem>
            </Link>
            <Link
              className={`${
                pathname.includes('/user/seller/order')
                  ? 'activeMenuItem'
                  : 'MenuItem'
              }  text-light`}
              to="/user/seller/order"
              style={{ textDecoration: 'none' }}
            >
              <MenuItem icon={<BiSolidCartDownload size={23} />}>
                Seller Orders
              </MenuItem>
            </Link>
            <Link
              className={`${
                pathname.includes('/user/buyer/order')
                  ? 'activeMenuItem'
                  : 'MenuItem'
              }  text-light`}
              to="/user/buyer/order"
              style={{ textDecoration: 'none' }}
            >
              <MenuItem icon={<BiSolidCartDownload size={23} />}>
                Buyer Orders
              </MenuItem>
            </Link>
          </>
        )}
        <Link
          className={`${
            pathname.includes('/user/profile') ? 'activeMenuItem' : 'MenuItem'
          }  text-light`}
          to="/user/profile"
          style={{ textDecoration: 'none' }}
        >
          <MenuItem icon={<IoPerson size={18} />}>Profile</MenuItem>
        </Link>
        <MenuItem
          className="MenuItem position-absolute bottom-0 w-100 text-light"
          icon={<SlLogout size={18} />}
          onClick={handleLogoutClick}
        >
          Log Out
        </MenuItem>
      </Menu>
    </div>
  );
});
