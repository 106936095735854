import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from '../../../../../app/providers/StoreProvider';
import { ApiError } from '../../../../../shared/types';
import { handleError } from '../../../../../shared/lib/helpers/utils';
import { ApplicationSettings } from '../types/applicationSettings';

export const fetchSettings = createAsyncThunk<
  ApplicationSettings,
  void,
  ThunkConfig<ApiError>
>(
  'applicationSettings/fetchSettings',
  async (_, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
      const response = await extra.api.get<ApplicationSettings>('/admin/application-settings');

      return response.data;
    } catch (err) {
      return rejectWithValue(handleError(err));
    }
  },
);
