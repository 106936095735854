import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkConfig } from '../../../../../app/providers/StoreProvider';
import { ApiError } from '../../../../../shared/types';
import { handleError } from '../../../../../shared/lib/helpers/utils';
import { PotentialOffer } from '../types/potentialOffer';
import { SortBy, SortOrder } from '../../../../../shared/const/table';

export interface FetchListParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
}

export const fetchList = createAsyncThunk<
  PotentialOffer[],
  FetchListParams,
  ThunkConfig<ApiError>
>(
  'potentialOffers/fetchList',
  async (params, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    let url = '/admin/potential-offers?';
    if (params.sortBy) {
      url += `&sortBy=${params.sortBy}`;
    }
    if (params.sortOrder) {
      url += `&sortOrder=${params.sortOrder}`;
    }

    try {
      const response = await extra.api.get<PotentialOffer[]>(url);

      return response.data;
    } catch (err) {
      return rejectWithValue(handleError(err));
    }
  },
);
