import { createAsyncThunk } from '@reduxjs/toolkit';

import { User } from '../../../User';
import { ThunkConfig } from '../../../../app/providers/StoreProvider';
import { ApiError } from '../../../../shared/types';
import { handleError } from '../../../../shared/lib/helpers/utils';
import { USER_LOCAL_STORAGE_KEY } from '../../../../shared/const/localStorage';
import { getAuthFirebaseToken } from '../selectors/getAuthSelectors';

interface LoginPayload {
  token: string,
  user: User
}

interface LoginProps {
  email: string;
  password: string;
}

export const login = createAsyncThunk<
  User,
  LoginProps,
  ThunkConfig<ApiError>
>(
  'auth/login',
  async (params, thunkApi) => {
    const { extra, getState, rejectWithValue } = thunkApi;

    const firebaseToken = getAuthFirebaseToken(getState());

    try {
      const response = await extra.api.post<LoginPayload>('/auth/login', {
        ...params,
        deviceToken: firebaseToken,
      });

      localStorage.setItem(USER_LOCAL_STORAGE_KEY, response.data.token);

      return response.data.user;
    } catch (err) {
      return rejectWithValue(handleError(err));
    }
  },
);
