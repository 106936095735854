import { memo, MouseEvent } from 'react';
import { PiUserThin } from 'react-icons/pi';

import { Skeleton } from '../../../../shared/ui/Skeleton';
import { Img } from '../../../../shared/ui/Img';
import { classNames } from '../../../../shared/lib/helpers/classNames';

import cls from './Avatar.module.scss';

interface AvatarProps {
  className?: string;
  src?: string | null;
  size?: number;
  onClick?: (event: MouseEvent) => void;
}

export const Avatar = memo((props: AvatarProps) => {
  const {
    className,
    src,
    size,
    onClick,
  } = props;

  if (src) {
    const fallback = (
      <Skeleton
        width={size ?? 45}
        height={size ?? 45}
        borderRadius={(size ?? 45) / 2}
      />
    );

    return (
      <Img
        style={{
          width: `${size ?? 45}px`,
          height: `${size ?? 45}px`,
        }}
        className={classNames(cls.Img, {}, [className])}
        src={src}
        onClick={onClick}
        fallback={fallback}
      />
    );
  }

  return (
    <div className={cls.Placeholder}>
      <PiUserThin size={36} />
    </div>
  );
});
