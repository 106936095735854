import axios from 'axios';

import { USER_LOCAL_STORAGE_KEY } from '../const/localStorage';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v1`,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem(USER_LOCAL_STORAGE_KEY) || '';

  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
    config.headers.maxBodyLength = 'Infinity';
  } else {
    config.headers['Content-Type'] = 'application/json';
    config.headers.Accept = 'application/json';
  }

  if (config.headers && token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  return Promise.reject(error);
});
