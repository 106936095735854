import { createSlice } from '@reduxjs/toolkit';

import { PotentialOffersSchema } from '../types/potentialOffersSchema';
import { fetchList } from '../services/fetchList';
import { skipOffer } from '../services/skipOffer';

const initialState: PotentialOffersSchema = {};

export const potentialOffersSlice = createSlice({
  name: 'potentialOffers',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchList.pending, (state) => {
        state.listIsLoading = true;
        state.listError = undefined;
      })
      .addCase(fetchList.fulfilled, (state, action) => {
        state.listIsLoading = false;
        state.listData = action.payload;
      })
      .addCase(fetchList.rejected, (state, action) => {
        state.listIsLoading = false;
        state.listError = action.payload;
      })
      .addCase(skipOffer.pending, (state) => {
        state.isSkipping = true;
        state.skippingError = undefined;
      })
      .addCase(skipOffer.fulfilled, (state) => {
        state.isSkipping = false;
      })
      .addCase(skipOffer.rejected, (state, action) => {
        state.isSkipping = false;
        state.skippingError = action.payload;
      });
  },
});

export const { actions: potentialOffersActions, reducer: potentialOffersReducer } = potentialOffersSlice;
